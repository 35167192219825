import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MNavbar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MLoginPopup from "./MLoginPopup/MLoginPopup";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import LoginContainer from "../../../../Container/MyAccount/LoginContainer/LoginContainer";
import { MY_ACCOUNT_ROUTES } from "../../../../Routing/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/Store/hooks";
import { SESSION_CONSTANTS } from "../../../../Services/Constants/SessionConstants";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import { HomeSlice } from "../../../../Store/Reducer/Home/HomeSlice";
import { UserProfileSlice } from "../../../../Store/Reducer/Account/UserProfileSlice";

function MNavBar() {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openLoginPopup, setOpenLoginPopup] = React.useState(false);

  const dispatch = useAppDispatch();
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenMenu(open);
    };

  return (
    <Box className="mnavbar">
      <Grid container columnSpacing={3} alignItems={"center"} width="100%">
        <Grid xs={6}>
          <img
            src="./images/1clickpolicy_logo.svg"
            height="40px"
            alt="1clickpolicy"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(COMMON_ROUTES.HOME);
            }}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid xs={6} textAlign={"right"}>
          <Link className="hamburger-menu" onClick={toggleDrawer("left", true)}>
            <img
              src="./images/menu_icon.svg"
              alt=""
              height={"25px "}
              width={"25px"}
            />
          </Link>
        </Grid>
      </Grid>

      {/* sidemenu */}
      <SwipeableDrawer
        anchor="left"
        open={openMenu}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Box className="menuWrapper">
          <Box className="top-sec">
            <Box className="user_box">
              <p>Hi User!</p>
            </Box>
            <p className="menu-heading mb-2">User Account</p>
            <Box padding="8px">
              {/* <Link className="dash-link mb-2">
                <span>Dashboard</span>
              </Link> */}
              {/* <br />
              <Link className="dash-link">
                <span>Logout</span>
              </Link> */}
              {USER_DATA.mobile.value ? (
                <>
                  <Link
                    className="dash-link mb-2"
                    onClick={(e) => {
                      window.location.href = `${FRONTEND_DOMAIN}${
                        MY_ACCOUNT_ROUTES.DASHBOARD
                      }${btoa(USER_DATA.mobile.value)}`;
                    }}
                  >
                    <span>Dashboard</span>
                  </Link>
                  <Link className="dash-link ml-1" onClick={handleLogout}>
                    <span>Logout</span>
                  </Link>
                </>
              ) : (
                <Link
                  className="dash-link"
                  onClick={() => setOpenLoginPopup(true)}
                >
                  Login
                </Link>
              )}
            </Box>
            <LoginContainer open={openLoginPopup} setOpen={setOpenLoginPopup} />
          </Box>

          <Box className="menu-section">
            <p className="menu-heading">Services</p>
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT_US);
                  }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => {
                    navigate(COMMON_ROUTES.CONTACT);
                  }}
                >
                  Contact
                </Link>
              </li>
              {/* <li>
                <Link className="nav-link">Career</Link>
              </li> */}
              <li>
                <Link
                  className="nav-link"
                  onClick={() => {
                    navigate(COMMON_ROUTES.CLAIM);
                  }}
                >
                  Claim
                </Link>
              </li>
              {/* <li>
                <Link className="nav-link">Complaint</Link>
              </li> */}
            </ul>
          </Box>
          <Box className="menu-section pt-0">
            <p className="menu-heading">Products</p>
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}
                >
                  Car Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}
                >
                  Bike Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}
                >
                  Term Insurance
                </Link>
              </li>
            </ul>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default MNavBar;
