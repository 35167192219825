import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./NavBar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import CustomButton from "../../../InputFields/CustomButton/CustomButton";
import LoginPopup from "./LoginPopup/LoginPopup";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import LoginContainer from "../../../../Container/MyAccount/LoginContainer/LoginContainer";

import { MY_ACCOUNT_ROUTES } from "../../../../Routing/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/Store/hooks";
import { SESSION_CONSTANTS } from "../../../../Services/Constants/SessionConstants";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import { HomeSlice } from "../../../../Store/Reducer/Home/HomeSlice";
import { UserProfileSlice } from "../../../../Store/Reducer/Account/UserProfileSlice";

function NavBar() {
  const [openCustomerLogin, setOpenCustomerLogin] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorE2(null);
  };

  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <Box className="navbar">
      <LoginContainer open={openCustomerLogin} setOpen={setOpenCustomerLogin} />
      <Grid container width="100%" columnSpacing={3} alignItems="center">
        <Grid xs={3}>
          <img
            src="./images/1clickpolicy_logo.svg"
            height="60px"
            alt="1clickpolicy"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(COMMON_ROUTES.HOME);
            }}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid xs={9}>
          <ul className="nav-list">
            <li>
              <Button
                className="nav-link product-link"
                id="basic-link"
                aria-controls={isMenuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? "true" : undefined}
                onClick={handleMenuClick}
                disableRipple
                sx={{
                  padding: 0,
                  color: COLORS.black,
                  fontWeight: 400,
                  textTransform: "capitalize",
                  fontSize: "14px",
                  lineHeight: "21px",
                  display: "inline-flex",
                  alignItems: "center",
                  "&:hover": {
                    background: "none",
                    color: COLORS.themeblue,
                  },
                }}
              >
                Products{" "}
                <KeyboardArrowDownRoundedIcon sx={{ marginLeft: "4px" }} />
              </Button>
              <Menu
                className="popoverMenu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-link",
                  sx: {
                    fontSize: "14px",
                    lineHeight: "20px",
                  },
                }}
              >
                <MenuItem
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                  Car Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                  Two-Wheeler Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                  Term Insurance
                </MenuItem>
                {/* <MenuItem>Non-Term Insurance</MenuItem> */}
              </Menu>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.ABOUT_US);
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.CONTACT);
                }}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.CLAIM);
                }}
              >
                Claim
              </Link>
            </li>
            {/* <li>
              <Link onClick={() => {}}>Face to Face</Link>
            </li> */}
            {USER_DATA.mobile.value ? (
              <li className="ctaBtn">
                <Button
                  className="greenMdBtn rounded"
                  id="basic-button"
                  aria-controls={anchorE2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorE2)}
                  onClick={(e) => {
                    dispatch(HomeSlice.actions.setPageStatus("MY_ACCOUNT"));
                    handleAccountClick(e);
                  }}
                >
                  Hi! User
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorE2}
                  open={Boolean(anchorE2)}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    // onClick={() => {
                    //   handleClose();
                    //   window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                    // }}
                    onClick={() => {
                      handleClose();
                      const encodedMobile = btoa(USER_DATA.mobile.value);
                      sessionStorage.setItem(
                        SESSION_CONSTANTS.USER_MOBILE_NO,
                        encodedMobile
                      );
                      window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;
                    }}
                  >
                    My account
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </li>
            ) : (
              <li>
                <CustomButton
                  className="secondaryBtn medium"
                  text="Login"
                  fullWidth
                  variant="contained"
                  onClick={() => setOpenCustomerLogin(true)}
                />
              </li>
            )}
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NavBar;
