import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Link, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../SupportingFiles/colors";
import CloseIcon from "@mui/icons-material/Close";
import RKTextField from "../../../../Component/InputFields/RKTextField/RKTextField";
import CustomButton from "../../../../Component/InputFields/CustomButton/CustomButton";
import NativeSelectDropdown from "../../../../Component/InputFields/NativeSelectDropdown/NativeSelectDropdown";
import SearchDropdown from "../../../../Component/InputFields/SearchDropdown/SearchDropdown";
import RadioText from "../../../../Component/InputFields/RadioText/RadioText";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import { EVDateInput } from "@evervent_pvt_ltd/ui-kit";

// custom hook to update the value
const useInputChange = (initialValue: string) => {
  const [value, setvalue] = useState(initialValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setvalue(event.target.value);
  };
  return { value, onChange: handleChange };
};

function MBikeForm({ open, setOpen }: { open: boolean; setOpen: any }) {
  const [bikeFormSteps, setbikeFormSteps] = useState<
    "oldStep1" | "oldStep2" | "newStep1" | "newStep2"
  >("oldStep1");

  const [formData, setFormData] = useState<{
    reg_number: { value: string; warning: boolean };
    reg_date: { value: string; warning: boolean };
    make_model: { value: string; warning: boolean };
    fuel_type: { value: string; warning: boolean };
    variant: { value: string; warning: boolean };
    policy_expiry: { value: string; warning: boolean };
    previous_insurer: { value: Date | null; warning: boolean };
    fullname: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    rto: { value: string; warning: boolean };
  }>({
    reg_number: { value: "", warning: false },
    reg_date: { value: "", warning: false },
    make_model: { value: "", warning: false },
    fuel_type: { value: "", warning: false },
    variant: { value: "", warning: false },
    policy_expiry: { value: "", warning: false },
    previous_insurer: { value: null, warning: false },
    fullname: { value: "", warning: false },
    mobile: { value: "", warning: false },
    rto: { value: "", warning: false },
  });

  const { value: ncbValue, onChange: changeNCB } = useInputChange("0");
  const { value: claimUpdate, onChange: changeClaim } = useInputChange("no");

  const make_model_data = [{ label: "Maruti Suzuki", value: "Maruti Suzuki" }];
  const fuel_type_data = [
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "CNG", value: "CNG" },
    { label: "Electric", value: "Electric" },
  ];
  const variant_data = [
    { label: "Baleno,1200cc,petrol", value: "Baleno,1200cc,petrol" },
  ];

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev: any) => ({ ...prev, [attrName]: { value: value } }));
  };
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, []);

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "#ffffff",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "84px",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          {/* topnav */}
          <Box
            sx={{
              backgroundColor: "rgba(255, 243, 244, 1)",
              height: "60px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "99",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="row"
              spacing={3}
              alignItems="center"
              width="100%"
            >
              <Grid xs={12} paddingLeft={"40px"} paddingRight={"40px"}>
                {bikeFormSteps === "oldStep2" ||
                bikeFormSteps === "newStep2" ? (
                  <Link
                    style={{
                      position: "absolute",
                      left: "12px",
                      top: "19px",
                      display: "inline-block",
                      cursor: "pointer",
                      paddingRight: "4px",
                      color: "#000",
                      height: "20px",
                    }}
                    onClick={() => {
                      if (bikeFormSteps === "oldStep2") {
                        setbikeFormSteps("oldStep1");
                      } else {
                        setbikeFormSteps("newStep1");
                      }
                    }}
                  >
                    <KeyboardArrowLeftIcon sx={{ fontSize: "20px" }} />
                  </Link>
                ) : null}
                <h6 style={{ textAlign: "center" }}>
                  {bikeFormSteps === "oldStep2" || bikeFormSteps === "newStep2"
                    ? "Confirming the Details"
                    : "Find the Best Bike Insurance"}
                </h6>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setbikeFormSteps("oldStep1");
                  }}
                  sx={{
                    position: "absolute",
                    right: "12px",
                    top: "19px",
                    maxWidth: "22px",
                    minWidth: "22px",
                    maxHeight: "22px",
                    color: COLORS.white,
                    backgroundColor: COLORS.themeGreen,
                    borderRadius: "60px",
                    transition: "all ease-in-out 0.2s",
                    "&:hover": {
                      backgroundColor: COLORS.black,
                      transform: "rotate(-90deg)",
                    },
                  }}
                >
                  <CloseIcon style={{ height: "18px", width: "18px" }} />
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* contentsec */}
          <Box>
            {bikeFormSteps === "oldStep1" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={10} marginBottom={"20px"}>
                  <RKTextField
                    class_name="inputField vehicleNumberField"
                    title={""}
                    value={formData.reg_number.value}
                    attrName={"reg_date"}
                    value_update={updateMasterState}
                    warn_status={formData.reg_number.warning}
                    error_message={"Enter reg. number"}
                    placeholder="MH01AB2345"
                  />
                </Grid>
                <Grid xs={10} marginBottom={"20px"}>
                  <EVDateInput
                    id="reg_date"
                    className="inputField"
                    title={"Reg. Date"}
                    value={formData?.reg_date?.value}
                    attrName={"reg_date"}
                    value_update={updateMasterState}
                    warn_status={formData.reg_date.warning}
                    error_message={"Select Reg. Date"}
                    min_date={15 * 12}
                    max_date={3}
                    date_validation_type="MONTHS"
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="Continue"
                    onClick={() => setbikeFormSteps("oldStep2")}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"}>
                  <Link
                    sx={{ color: COLORS.black }}
                    onClick={() => setbikeFormSteps("newStep1")}
                  >
                    Bought a{" "}
                    <span style={{ color: COLORS.themeGreen }}>New Bike</span>
                  </Link>
                </Grid>
              </Grid>
            ) : bikeFormSteps === "oldStep2" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid xs={12} marginBottom={"20px"}>
                  <SearchDropdown
                    class_name="inputField"
                    title={"Make & Model"}
                    attrName={"make_model"}
                    value={`${formData.make_model.value}`}
                    value_update={updateMasterState}
                    data={make_model_data}
                    warn_status={formData.make_model.warning}
                    error_message={"Select Make and Model"}
                  />
                </Grid>
                <Grid xs={4} marginBottom={"20px"} paddingRight={"6px"}>
                  <NativeSelectDropdown
                    class_name="inputField"
                    title={"Fuel Type"}
                    attrName={"fuel_type"}
                    value={formData.fuel_type.value}
                    value_update={updateMasterState}
                    data={fuel_type_data}
                    warn_status={formData.fuel_type.warning}
                    error_message={"Select Fuel Type"}
                  />
                </Grid>
                <Grid xs={8} marginBottom={"20px"} paddingLeft={"6px"}>
                  <SearchDropdown
                    class_name="inputField"
                    title={"Variant"}
                    attrName={"variant"}
                    value={`${formData.variant.value}`}
                    value_update={updateMasterState}
                    data={variant_data}
                    warn_status={formData.variant.warning}
                    error_message={"Select variant"}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "10px",
                    }}
                  >
                    Policy Details
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    Please enter all the essential details pertaining to the
                    policy.
                  </p>
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                  <EVDateInput
                    id="policy_expiry"
                    className="inputField"
                    title={"Policy Expiry"}
                    value={formData?.policy_expiry?.value}
                    attrName={"policy_expiry"}
                    value_update={updateMasterState}
                    warn_status={formData.policy_expiry.warning}
                    error_message={"Select Policy Expiry Date"}
                    min_date={15 * 12}
                    max_date={3}
                    date_validation_type="MONTHS"
                  />
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                  <NativeSelectDropdown
                    class_name="inputField"
                    title={"Previous Insurer"}
                    attrName={"previous_insurer"}
                    value={formData.previous_insurer.value}
                    value_update={updateMasterState}
                    data={fuel_type_data}
                    warn_status={formData.previous_insurer.warning}
                    error_message={"Select previous insurer"}
                  />
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    value={formData.fullname.value}
                    attrName={"fullname"}
                    value_update={updateMasterState}
                    warn_status={formData.fullname.warning}
                    error_message={"Enter Full name"}
                  />
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={"Enter Mobile"}
                  />
                </Grid>
                <Grid
                  xs={6}
                  textAlign={"center"}
                  marginBottom={"24px"}
                  paddingRight={"6px"}
                >
                  <p style={{ fontWeight: 500 }}>Claim in the last year</p>
                </Grid>
                <Grid
                  xs={6}
                  textAlign={"center"}
                  marginBottom={"24px"}
                  paddingLeft={"6px"}
                >
                  <RadioText
                    count="AUTO"
                    orientation="row"
                    variant="xsmall"
                    attrName="lastClaimed"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    onChange={changeClaim}
                    value={claimUpdate}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <p style={{ fontWeight: 500 }}>
                    Previous No Claim Bonus (NCB)
                  </p>
                  <p
                    className="my-2"
                    style={{ fontSize: "12px", color: COLORS.black }}
                  >
                    {" "}
                    (Check it in your last policy){" "}
                  </p>
                  <RadioText
                    count="AUTO"
                    orientation="row"
                    variant="small"
                    attrName="ncb"
                    options={[
                      { label: "0%", value: "0" },
                      { label: "20%", value: "20" },
                      { label: "25%", value: "25" },
                      { label: "35%", value: "35" },
                      { label: "45%", value: "45" },
                      { label: "50%", value: "50" },
                    ]}
                    onChange={changeNCB}
                    value={ncbValue}
                    justifyContent={"center"}
                  />
                </Grid>

                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="View Quotes"
                    onClick={() => {}}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid
                  xs={12}
                  textAlign={"center"}
                  marginBottom={"24px"}
                  className="popup-Checkbox"
                >
                  <CustomCheckbox
                    label={
                      <>
                        By clicking on view quotes you agree to all
                        <span> Terms & Conditions.</span>
                      </>
                    }
                    attrName={"termCondition"}
                    value={true}
                    // value_update={handleCheckbox}
                  />
                </Grid>
              </Grid>
            ) : bikeFormSteps === "newStep1" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={10} marginBottom={"20px"}>
                  <SearchDropdown
                    class_name="inputField"
                    title={"RTO"}
                    attrName={"rto"}
                    value={`${formData.rto.value}`}
                    value_update={updateMasterState}
                    data={make_model_data}
                    warn_status={formData.rto.warning}
                    error_message={"Select Make and Model"}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="Continue"
                    onClick={() => setbikeFormSteps("newStep2")}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"}>
                  <Link
                    sx={{ color: COLORS.black }}
                    onClick={() => setbikeFormSteps("oldStep1")}
                  >
                    Back
                  </Link>
                </Grid>
              </Grid>
            ) : bikeFormSteps === "newStep2" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={12} marginBottom={"20px"}>
                  <SearchDropdown
                    class_name="inputField"
                    title={"Make & Model"}
                    attrName={"make_model"}
                    value={`${formData.make_model.value}`}
                    value_update={updateMasterState}
                    data={make_model_data}
                    warn_status={formData.make_model.warning}
                    error_message={"Select Make and Model"}
                  />
                </Grid>
                <Grid xs={4} marginBottom={"20px"} paddingRight={"6px"}>
                  <NativeSelectDropdown
                    class_name="inputField"
                    title={"Fuel Type"}
                    attrName={"fuel_type"}
                    value={formData.fuel_type.value}
                    value_update={updateMasterState}
                    data={fuel_type_data}
                    warn_status={formData.fuel_type.warning}
                    error_message={"Select Fuel Type"}
                  />
                </Grid>
                <Grid xs={8} marginBottom={"20px"} paddingLeft={"6px"}>
                  <SearchDropdown
                    class_name="inputField"
                    title={"Variant"}
                    attrName={"variant"}
                    value={`${formData.variant.value}`}
                    value_update={updateMasterState}
                    data={variant_data}
                    warn_status={formData.variant.warning}
                    error_message={"Select variant"}
                  />
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    value={formData.fullname.value}
                    attrName={"fullname"}
                    value_update={updateMasterState}
                    warn_status={formData.fullname.warning}
                    error_message={"Enter Full name"}
                  />
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={"Enter Mobile"}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="Continue"
                    onClick={() => {}}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} className="popup-Checkbox">
                  <CustomCheckbox
                    label={
                      <>
                        By clicking on view quotes you agree to all
                        <span> Terms & Conditions.</span>
                      </>
                    }
                    attrName={"termCondition"}
                    // value={termCondition}
                    // value_update={handleCheckbox}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MBikeForm;
