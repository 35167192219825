import React from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MFooter.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";

function MFooter() {
  const navigate = useNavigate();
  return (
    <Box className="mfooter">
      <Box className="top-sec">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box textAlign={"center"} marginBottom={"24px"}>
              <img
                src="./images/1clickpolicy-white-logo.svg"
                height="60px"
                alt="1clickpolicy"
              />
            </Box>
          </Grid>
          <Grid xs={6} marginBottom={"20px"}>
            <h6 className="mb-3">Address</h6>
            <p className="mb-2">
              A-26/1, DLF Phase 1, Golf course Road ,Gurgaon, Haryana.
            </p>
            <p className="mb-2">
              IRDAI License No: 713 <br /> Valid upto: 07-06-2026
            </p>
            <p>CIN: U67345MH2C123456</p>
          </Grid>
          <Grid xs={6} marginBottom={"20px"}>
            <h6 className="mb-3">Services</h6>
            <ul>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}>
                  Health Insurance
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                  Car Insurance
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                  Bike Insurance
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                  Term Insurance
                </Link>
              </li>
              {/* <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                  Investment Plan
                </Link>
              </li> */}
            </ul>
          </Grid>
          <Grid xs={6} marginBottom={"20px"}>
            <h6 className="mb-4">Quick Links</h6>
            <ul>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.HOME)}>Home</Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                  About Us
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.CONTACT)}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.CLAIM)}>Claim</Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.COMPLAINT)}>
                  Complaint
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}>
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRIVACY_POLICY)}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.DISCLAIMER)}>
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => navigate(COMMON_ROUTES.REFUND_CANCELATION)}
                >
                  Refund & Policy Cancellation
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid xs={6} marginBottom={"20px"}>
            <h6 className="mb-4">Get in Touch</h6>
            <p className="mb-3">
              <span>Contact:</span>
              <br /> +91-8055875587
            </p>
            <p className="mb-3">
              <span>Support Mail:</span>
              <br />{" "}
              <Link href="mailto:support@1clickpolicy.com">
                support@1clickpolicy.com
              </Link>
            </p>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} textAlign={"center"}>
            <p
              style={{
                display: "inline-block",
                color: COLORS.white,
                padding: "8px 16px",
                borderRadius: "24px",
                background: "rgba(255, 255, 255, 0.1)",
                fontSize: "10px",
                lineHeight: "15px",
                fontWeight: 300,
              }}
            >
              DISCLAIMER: All the images used on 1clickpolicy.com have either
              been purchased or are used with permission of the copyright
              holder. Images from 1clickpolicy.com may not be copied or used
              elsewhere without obtaining appropriate permissions from the
              copyright holders.
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box className="copyright-bar">
        <p style={{ textAlign: "center" }}>
          © {new Date().getFullYear()} 1 ClickPolicy | All Rights Reserved.
        </p>
      </Box>
    </Box>
  );
}

export default MFooter;
