import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../Store/Store/hooks";
import SearchDropdown from "../../Component/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../Component/InputFields/SelectDropdown/SelectDropdown";
import RKTextField from "../../Component/InputFields/RKTextField/RKTextField";
import { formatDate, isEmpty } from "../../SupportingFiles/HelpingFunction";
import CustomButton from "../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import { COMMON_ROUTES } from "../../Routing/Path/CommonRoutes";
import { EVDateInput } from "@evervent_pvt_ltd/ui-kit";
function TermProductFormPage({
  annual_income_data,
  age_data,
  gender_data,
  smokinStatus_data,
  updateMasterState,
  validate_form,
  loader,
  formData,
}: {
  annual_income_data: any;
  age_data: any;
  gender_data: any;
  formData: any;
  updateMasterState: Function;
  loader: any;
  validate_form: Function;
  smokinStatus_data: any;
}) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Term } = useAppSelector((state) => state);
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ xs: 2, md: 3 }}
      textAlign="center"
      justifyContent={"center"}
    >
      <Grid xs={6}>
        <SearchDropdown
          class_name="inputField mb-2"
          title={"Annual Income"}
          attrName={"annualIncome"}
          value={`${formData.annualIncome.value}`}
          value_update={updateMasterState}
          data={annual_income_data}
          warn_status={formData.annualIncome.warning}
          error_message={"Select Annual Income"}
        />
      </Grid>
      <Grid xs={6}>
        <SelectDropdown
          class_name="inputField  mb-2"
          title={"Do you smoke?"}
          attrName={"smoke"}
          value={formData.smoke.value}
          value_update={updateMasterState}
          data={smokinStatus_data}
          warn_status={formData.smoke.warning}
          error_message={"Select Smoker"}
        />
      </Grid>
      <Grid xs={6}>
        <SelectDropdown
          class_name="inputField  mb-2"
          title={"Your Gender"}
          attrName={"gender"}
          value={formData.gender.value}
          value_update={updateMasterState}
          data={gender_data}
          warn_status={formData.gender.warning}
          error_message={"Select Gender"}
        />
      </Grid>
      <Grid xs={6}>
        <EVDateInput
          id="age"
          className="inputField mb-2"
          title={"Date of Birth"}
          value={formData?.age?.value ? formData?.age?.value : formatDate(subYears(new Date(), 18))}
          attrName={"age"}
          value_update={updateMasterState}
          warn_status={formData.age.warning}
          error_message="Select DOB"
          min_date={65}
          max_date={18}
        />
      </Grid>
      <Grid xs={6}>
        <RKTextField
          class_name="inputField mb-2"
          title={"Pincode"}
          value={formData.pincode.value}
          attrName={"pincode"}
          value_update={updateMasterState}
          warn_status={formData.pincode.warning}
          error_message={
            isEmpty(formData.pincode.value)
              ? "Enter Pincode"
              : "Enter Valid Pincode"
          }
          max_length={6}
          validation_type="NUMBER"
        />
      </Grid>
      <Grid xs={6}>
        <RKTextField
          class_name="inputField mb-2"
          title={"Mobile"}
          value={formData.mobile.value}
          attrName={"mobile"}
          value_update={updateMasterState}
          warn_status={formData.mobile.warning}
          error_message={
            isEmpty(formData.mobile.value)
              ? "Enter Mobile Number"
              : "Enter Valid Mobile Number"
          }
          max_length={10}
          validation_type="NUMBER"
        />
      </Grid>

      <Grid xs={12}>
        <CustomButton
          className="primaryBtn"
          text="View Quotes"
          // onClick={() => {
          //   validate_form();
          // }}
          onClick={() => {
            validate_form();
            // if (Term?.ADD_FORM?.term_cond?.value) {
            // } else {
            //   toast.error("Please accept terms and conditions");
            // }
          }}
          fullWidth={false}
          variant="contained"
        />
      </Grid>
      <Grid xs={12} className="popup-Checkbox">
        <CustomCheckbox
          checked={true}
          // label="By clicking on view quotes you agree to all Terms & Conditions."
          label={
            <>
              By clicking on view quotes you agree to all {""}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}
              >
                Terms & Conditions
              </span>
            </>
          }
          defaultChecked
          value={true}
          disabled={true}
          attrName={"termCondition"}
          // value={Term?.ADD_FORM?.term_cond?.value}
          // defaultChecked={Term?.ADD_FORM?.term_cond?.value}
          // value_update={(a: any, v: any) => {
          //   dispatch(
          //     TermSlice.actions.setTermAddFormData({
          //       ...Term.ADD_FORM,
          //       term_cond: {
          //         value: v,
          //         warning: isEmpty(v),
          //       },
          //     })
          //   );
          // }}
        />
      </Grid>
    </Grid>
  );
}

export default TermProductFormPage;
