import React from "react";
import { Box, Button } from "@mui/material";
import "./CustomButton.scss";
import Loader from "../../Loader/Loader";

interface ButtonProps {
  onClick?: () => void;
  text: React.ReactNode;
  variant: "text" | "outlined" | "contained";
  className: string;
  fullWidth?: boolean;
  loader?: boolean;
}

const CustomButton: React.FC<ButtonProps> = ({
  onClick,
  text,
  variant,
  className,
  fullWidth = true,
  loader = false,
}) => {
  return (
    <Box className={className}>
      <Button variant={variant} onClick={onClick} disableRipple>
        {loader ? <Loader size="small" /> : text}
      </Button>
    </Box>
  );
};

export default CustomButton;
