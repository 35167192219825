import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";
import CarProductContainer from "../../../Container/CarProductContainer/CarProductContainer";

function CarProductPage() {
  const isMobile = useIsMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="cmsheader">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"30px"}>
            <h1 style={{ textAlign: "center" }}>Car Insurance</h1>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1 header-section"
          marginBottom={"60px"}
          alignItems={"center"}
        >
          <Grid xs={12} marginBottom={"24px"} className="mb-margin2 ">
            <h3>BEST CAR INSURANCE POLICY IN INDIA</h3>
            <p style={{ marginBottom: "16px" }}>
              Just like life insurance, it is important to get motor insurance
              for any vehicle owner in India. Among all the motor insurance, car
              insurance can be regarded as one of the important ones. Driving a
              car of your own in India is a luxury in itself, however, it also
              comes with tons of risk factors, and one of them is damage to your
              car. Now just think about all the hard work you have put in to get
              a brand new car, working late at the office, pulling double shifts
              to make some extra money, just to see it all go into a vein
              because you did get your car insured. It is disturbing and scary,
              isn’t it? Instead of worrying, you need the best car insurance
              policy in India. It eliminates risks and puts your mind at ease.
              If you are still not convinced why you should get your car insured
              then we have a few more reasons that will change your mind.
            </p>
          </Grid>
          <Grid xs={12} md={6} className="mb-margin2" textAlign={"center"}>
            <img
              src="./images/car-cms.svg"
              alt="insurance-policy"
              style={{
                maxWidth: "300px",
                width: "100%",
                margin: "auto",
              }}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="form-box-pp">
              <CarProductContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 className="mb-4">
              Types of Best Car Insurances Policy In India
            </h2>
            <ul className="thingsConsiderationlist">
              <li>
                <h5>Comprehensive Car Insurance</h5>

                <p>
                  Comprehensive car insurance is the best car insurance policy
                  in India. It is a bundle of coverages and compensations as it
                  not only covers the liability to the third party that you are
                  legally bound to pay, but it also covers own-damages incurred
                  in an uncertain event. Among all the three types of insurance,
                  it will provide you with the maximum coverage against any
                  unforeseen event such as fire, accidents, collision, theft,
                  vandalism, natural disaster or any man-made disasters.
                </p>
                <p>
                  A comprehensive car insurance policy can be further extended
                  with add-ons such as engine protector, accessories cover,
                  medical bills, zero depreciation cover, etc. This is the most
                  popular insurance policy amongst car owners, as it covers
                  almost everything from end-to-end.
                </p>
              </li>
              <li>
                <h5>Third-Party Car Insurance</h5>
                <p>
                  According to Indian Motor Tariff, one who owns the car must
                  have third-party car insurance. This is because this insurance
                  policy provides coverage for all the legal liabilities that
                  occurred to a third-party due to the involvement of your car
                  in an accident. This policy will compensate for the death,
                  bodily injury, disability or any property damage to the
                  third-party due to the uncertainty. This way, you will not
                  have any legal financial liability against the third-party.
                </p>
                <p>
                  However, this insurance policy does not provide compensation
                  for the damages that occurred to the insured car or its
                  owner/driver. Under the Motor Vehicle Act, 1988, all the
                  third-party car insurance prices are mandated.
                </p>
              </li>
              <li>
                <h5>Standalone Own-Damage Car Insurance</h5>
                <p>
                  As the name suggests, standalone own-damage car insurance
                  provides protection and compensation for the damages sustained
                  by the insured car only. It covers uncontrollable damages
                  incurred during an unfortunate event such as theft, crash,
                  fire, natural calamities, man made disasters, etc. There is no
                  provision for third-party damage.
                </p>
                <p>
                  However, you can buy this insurance policy if only you already
                  have a third-party car insurance policy for legal
                  requirements. This policy also allows you to buy third-party
                  car insurance from one company and standalone own-damage car
                  insurance from another company.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 className="mb-4">Why Get Your Car Insured?</h2>
            <ul className="thingsConsiderationlist">
              <li>
                <h5>Payment for damages</h5>

                <p>
                  If you own a car, then you most certainly know these
                  automobiles aren’t coming cheap. Along with the car, their
                  repair and spare parts come expensive too. But, if you have
                  car insurance, you won’t have the burden of cost and repair
                  charges on your shoulders.
                </p>
              </li>
              <li>
                <h5>Hospital Bills</h5>
                <p>
                  Not everyone is lucky enough to walk away with a few scratches
                  and bruises in a car accident. The majority of car accidents
                  result in broken bones and serious injuries. Other than the
                  car being crashed, hospitalization is another expensive affair
                  that can empty your bank account with all the medical tests,
                  medicines, hospital charges, etc. But if you have car
                  insurance, you don’t have to worry even for a second because
                  it will cover all the charges.
                </p>
              </li>
              <li>
                <h5>Protection from liability</h5>
                <p>
                  Getting the best car insurance policy in India will not only
                  protect you but also others. If you have been in a car
                  accident injuring another driver or pedestrian or other
                  property, the right car insurance will protect you from paying
                  others bills. The car insurance will take care of others
                  property damage, reducing your liability.
                </p>
              </li>
              <li>
                <h5>Pay Less Premium For Online Car Insurance</h5>
                <p>
                  Buying car insurance online can be fruitful, as you may get a
                  cheaper policy with a lower premium rate. You can also save a
                  lot of time in renewing the policy which is usually wasted in
                  travelling to the offices. It also saves you tons of paperwork
                  and eliminates the broker who acts as an intermediator.
                </p>
              </li>
              <li>
                <h5>Compensation After Demise</h5>
                <p>
                  The death of a person is a loss that can not be covered by
                  anything in the world. But a demise in a car accident not only
                  brings a lot of pain but other troubles as well. No amount can
                  compensate for the death, but a car insurance policy can help
                  pay for expenses after an uncertain event.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1"
          marginBottom={"60px"}
        >
          <Grid xs={12} marginBottom={"40px"} className="mb-margin2">
            <h2 style={{ textAlign: "center" }}>
              Key features <span> of Car Insurance </span>
            </h2>
          </Grid>
          <Grid xs={12}>
            <ul className="keyFeature_ci_list">
              <li>
                <h6 style={{ marginBottom: "8px" }}>Own Damage Cover</h6>
                <p style={{ color: COLORS.black }}>
                  Available under Comprehensive and Standalone plan{" "}
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "8px" }}>
                  Third Party Damage Cover
                </h6>
                <p style={{ color: COLORS.black }}>
                  Covers third party injury and property damage
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "8px" }}>Savings</h6>
                <p style={{ color: COLORS.black }}>
                  Up to 80% on Car Insurance Premium
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "8px" }}>Car Insurance add-ons</h6>
                <p style={{ color: COLORS.black }}>
                  10+ add-ons according to the plan
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "8px" }}>Personal Accident Cover</h6>
                <p style={{ color: COLORS.black }}>Upto ₹15 Lakh</p>
              </li>
              <li>
                <h6 style={{ marginBottom: "8px" }}>Cashless Repairs</h6>
                <p style={{ color: COLORS.black }}>Available</p>
              </li>
              <li>
                <h6 style={{ marginBottom: "8px" }}>Car Insurance Premium</h6>
                <p style={{ color: COLORS.black }}>Starting @ ₹2072/year*</p>
              </li>
              <li>
                <h6 style={{ marginBottom: "8px" }}>
                  Buying/Renewal of Policy
                </h6>
                <p style={{ color: COLORS.black }}>Online & Offline</p>
              </li>
              <li>
                <h6 style={{ marginBottom: "8px" }}>No Claim Bonus</h6>
                <p style={{ color: COLORS.black }}>Upto 50%</p>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default CarProductPage;
