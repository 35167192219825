import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../SupportingFiles/colors";
import CloseIcon from "@mui/icons-material/Close";
import RKTextField from "../../../../Component/InputFields/RKTextField/RKTextField";
import CustomButton from "../../../../Component/InputFields/CustomButton/CustomButton";
import NativeSelectDropdown from "../../../../Component/InputFields/NativeSelectDropdown/NativeSelectDropdown";
import SearchDropdown from "../../../../Component/InputFields/SearchDropdown/SearchDropdown";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";

function MTermForm({ open, setOpen }: { open: boolean; setOpen: any }) {
  const [formData, setFormData] = useState<{
    annual_Income: { value: string; warning: boolean };
    do_you_smoke: { value: string; warning: boolean };
    your_gender: { value: string; warning: boolean };
    your_age: { value: string; warning: boolean };
    pincode: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
  }>({
    annual_Income: { value: "", warning: false },
    do_you_smoke: { value: "", warning: false },
    your_gender: { value: "", warning: false },
    your_age: { value: "", warning: false },
    pincode: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });

  const annual_incomme_data: { label: string; value: string }[] = [];
  for (let i = 1; i <= 99; i++) {
    annual_incomme_data.push({
      label: `${i} Lakh${i === 1 ? "" : "s"}`,
      value: `${i} Lakh${i === 1 ? "" : "s"}`,
    });
  }

  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const smokinStatus_data = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const age_data: { label: string; value: string }[] = [];
  for (let i = 18; i <= 90; i++) {
    age_data.push({ label: `${i} Years`, value: `${i} Years` });
  }

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, []);

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "#ffffff",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "84px",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          {/* topnav */}
          <Box
            sx={{
              backgroundColor: "rgba(255, 243, 244, 1)",
              height: "60px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "99",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="row"
              spacing={3}
              alignItems="center"
              width="100%"
            >
              <Grid xs={12} paddingLeft={"40px"} paddingRight={"40px"}>
                <h6 style={{ textAlign: "center" }}>
                  Find the Best Term Insurance
                </h6>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  sx={{
                    position: "absolute",
                    right: "12px",
                    top: "19px",
                    maxWidth: "22px",
                    minWidth: "22px",
                    maxHeight: "22px",
                    color: COLORS.white,
                    backgroundColor: COLORS.themeGreen,
                    borderRadius: "60px",
                    transition: "all ease-in-out 0.2s",
                    "&:hover": {
                      backgroundColor: COLORS.black,
                      transform: "rotate(-90deg)",
                    },
                  }}
                >
                  <CloseIcon style={{ height: "18px", width: "18px" }} />
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* contentsec */}
          <Box>
            <Grid
              container
              className="row"
              columnSpacing={3}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                <SearchDropdown
                  class_name="inputField"
                  title={"Annual Income"}
                  attrName={"annual_Income"}
                  value={`${formData.annual_Income.value}`}
                  value_update={updateMasterState}
                  data={annual_incomme_data}
                  warn_status={formData.annual_Income.warning}
                  error_message={"Select Annual Income"}
                />
              </Grid>
              <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                <NativeSelectDropdown
                  class_name="inputField"
                  title={"Do you smoke?"}
                  attrName={"do_you_smoke"}
                  value={formData.do_you_smoke.value}
                  value_update={updateMasterState}
                  data={smokinStatus_data}
                  warn_status={formData.do_you_smoke.warning}
                  error_message={"Select smoking status"}
                />
              </Grid>
              <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                <NativeSelectDropdown
                  class_name="inputField"
                  title={"Your Gender"}
                  attrName={"your_gender"}
                  value={formData.your_gender.value}
                  value_update={updateMasterState}
                  data={gender_data}
                  warn_status={formData.your_gender.warning}
                  error_message={"Select gender"}
                />
              </Grid>
              <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                <SearchDropdown
                  class_name="inputField"
                  title={"Your Age"}
                  attrName={"your_age"}
                  value={`${formData.your_age.value}`}
                  value_update={updateMasterState}
                  data={age_data}
                  warn_status={formData.your_age.warning}
                  error_message={"Select age"}
                />
              </Grid>
              <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                <RKTextField
                  class_name="inputField"
                  title={"Pincode"}
                  value={formData.pincode.value}
                  attrName={"pincode"}
                  value_update={updateMasterState}
                  warn_status={formData.pincode.warning}
                  error_message={"Enter pincode"}
                />
              </Grid>
              <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                <RKTextField
                  class_name="inputField "
                  title={"Mobile"}
                  value={formData.mobile.value}
                  attrName={"mobile"}
                  value_update={updateMasterState}
                  warn_status={formData.mobile.warning}
                  error_message={"Enter Mobile"}
                />
              </Grid>

              <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                <CustomButton
                  className="primaryBtn "
                  text="View Quotes"
                  onClick={() => {}}
                  fullWidth={false}
                  variant="contained"
                />
              </Grid>
              <Grid
                xs={12}
                textAlign={"center"}
                marginBottom={"24px"}
                className="popup-Checkbox"
              >
                <CustomCheckbox
                  label={
                    <>
                      By clicking on view quotes you agree to all
                      <span> Terms & Conditions.</span>
                    </>
                  }
                  attrName={"termCondition"}
                  value={true}
                  // value_update={handleCheckbox}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MTermForm;
