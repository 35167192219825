import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Link, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../SupportingFiles/colors";
import CloseIcon from "@mui/icons-material/Close";
import RKTextField from "../../../../Component/InputFields/RKTextField/RKTextField";
import CustomButton from "../../../../Component/InputFields/CustomButton/CustomButton";
import NativeSelectDropdown from "../../../../Component/InputFields/NativeSelectDropdown/NativeSelectDropdown";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { EVDateInput } from "@evervent_pvt_ltd/ui-kit";

function MHealthForm({ open, setOpen }: { open: boolean; setOpen: any }) {
  const [healthFormSteps, setHealthFormSteps] = useState<
    "healthStep1" | "healthStep2"
  >("healthStep1");

  const [formData, setFormData] = useState<{
    pincode: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    gender: { value: string; warning: boolean };
    relationship: { value: string; warning: boolean };
    firstAdult_dob: { value: string; warning: boolean };
    secondAdult_dob: { value: string; warning: boolean };
    son_dob: { value: string; warning: boolean };
    daughter_dob: { value: string; warning: boolean };
  }>({
    pincode: { value: "", warning: false },
    mobile: { value: "", warning: false },
    gender: { value: "", warning: false },
    relationship: { value: "", warning: false },
    firstAdult_dob: { value: "", warning: false },
    secondAdult_dob: { value: "", warning: false },
    son_dob: { value: "", warning: false },
    daughter_dob: { value: "", warning: false },
  });

  const annual_incomme_data: { label: string; value: string }[] = [];
  for (let i = 1; i <= 99; i++) {
    annual_incomme_data.push({ label: `${i}`, value: `${i}` });
  }

  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const relationship_data = [
    { label: "Spouse", value: "Spouse" },
    { label: "Mother", value: "Mother" },
    { label: "Father", value: "Father" },
  ];

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  const [memberStatus, setMemberstatus] = useState({
    oneAdult: true,
    twoAdults: false,
    son: false,
    daughter: false,
  });

  const handleUpdateMemberStatus = (attrName: string, value: boolean) => {
    setMemberstatus((prev) => ({ ...prev, [attrName]: value }));
  };

  const [sonCount, setSonCount] = useState(1);
  const handleSCIncrease = () => {
    if (sonCount < 4) {
      setSonCount(sonCount + 1);
    }
  };
  const handleSCDecrease = () => {
    if (sonCount > 1) {
      setSonCount(sonCount - 1);
    }
  };
  const [daughterCount, setDaughterCount] = useState(1);
  const handleDIncrease = () => {
    if (daughterCount < 4) {
      setDaughterCount(daughterCount + 1);
    }
  };
  const handleDDecrease = () => {
    if (daughterCount > 1) {
      setDaughterCount(daughterCount - 1);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, []);

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "#ffffff",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "84px",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          {/* topnav */}
          <Box
            sx={{
              backgroundColor: "rgba(255, 243, 244, 1)",
              height: "60px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "99",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="row"
              spacing={3}
              alignItems="center"
              width="100%"
            >
              <Grid xs={12} paddingLeft={"40px"} paddingRight={"40px"}>
                {healthFormSteps === "healthStep2" ? (
                  <Link
                    style={{
                      position: "absolute",
                      left: "12px",
                      top: "19px",
                      display: "inline-block",
                      cursor: "pointer",
                      paddingRight: "4px",
                      color: "#000",
                      height: "20px",
                    }}
                    onClick={() => setHealthFormSteps("healthStep1")}
                  >
                    <KeyboardArrowLeftIcon sx={{ fontSize: "20px" }} />
                  </Link>
                ) : null}
                <h6 style={{ textAlign: "center" }}>
                  {healthFormSteps === "healthStep2"
                    ? "Personal Details"
                    : "Find the Best Health Insurance"}
                </h6>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setHealthFormSteps("healthStep1");
                  }}
                  sx={{
                    position: "absolute",
                    right: "12px",
                    top: "19px",
                    maxWidth: "22px",
                    minWidth: "22px",
                    maxHeight: "22px",
                    color: COLORS.white,
                    backgroundColor: COLORS.themeGreen,
                    borderRadius: "60px",
                    transition: "all ease-in-out 0.2s",
                    "&:hover": {
                      backgroundColor: COLORS.black,
                      transform: "rotate(-90deg)",
                    },
                  }}
                >
                  <CloseIcon style={{ height: "18px", width: "18px" }} />
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* contentsec */}
          <Box>
            {healthFormSteps === "healthStep1" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={12} marginBottom={"12px"}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    Select the family members you want to insure
                  </p>
                </Grid>
                <Grid xs={6} paddingRight={"6px"}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                      position: "relative",
                    }}
                    className="self-selectField"
                  >
                    <CustomCheckbox
                      label="One Adult "
                      attrName={"oneAdult"}
                      value={memberStatus.oneAdult}
                      value_update={handleUpdateMemberStatus}
                    />
                  </Box>
                </Grid>
                <Grid xs={6} paddingLeft={"6px"}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                    }}
                  >
                    <CustomCheckbox
                      label="Two Adults"
                      attrName={"twoAdults"}
                      value={memberStatus.twoAdults}
                      value_update={handleUpdateMemberStatus}
                    />
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                      position: "relative",
                    }}
                  >
                    <CustomCheckbox
                      label="Son "
                      attrName={"son"}
                      value={memberStatus.son}
                      value_update={handleUpdateMemberStatus}
                    />
                    {memberStatus.son && (
                      <Box
                        position="absolute"
                        right="8px"
                        top="12px"
                        display="flex"
                        alignItems="center"
                        gap="8px"
                        zIndex="9"
                      >
                        <Box
                          onClick={handleSCDecrease}
                          height="16px"
                          width="16px"
                          color={COLORS.themeGreen}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <RemoveRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                        <Box
                          height="16px"
                          width="16px"
                          color={COLORS.themeGreen}
                          borderRadius="40px"
                          textAlign="center"
                          sx={{
                            backgroundColor: "rgba(255, 243, 244, 1)",
                            fontSize: "12px",
                          }}
                        >
                          {sonCount}
                        </Box>
                        <Box
                          onClick={handleSCIncrease}
                          height="16px"
                          width="16px"
                          color={COLORS.themeGreen}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <ControlPointIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid xs={12} marginBottom={"8px"}>
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "10px 12px 10px 8px",
                      marginBottom: "12px",
                      position: "relative",
                    }}
                  >
                    <CustomCheckbox
                      label="Daughter "
                      attrName={"daughter"}
                      value={memberStatus.daughter}
                      value_update={handleUpdateMemberStatus}
                    />
                    {memberStatus.daughter && (
                      <Box
                        position="absolute"
                        right="8px"
                        top="12px"
                        display="flex"
                        alignItems="center"
                        gap="8px"
                        zIndex="9"
                      >
                        <Box
                          onClick={handleDDecrease}
                          height="16px"
                          width="16px"
                          color={COLORS.themeGreen}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <RemoveRoundedIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                        <Box
                          height="16px"
                          width="16px"
                          color={COLORS.themeGreen}
                          borderRadius="40px"
                          textAlign="center"
                          sx={{
                            backgroundColor: "rgba(255, 243, 244, 1)",
                            fontSize: "12px",
                          }}
                        >
                          {daughterCount}
                        </Box>
                        <Box
                          onClick={handleDIncrease}
                          height="16px"
                          width="16px"
                          color={COLORS.themeGreen}
                          textAlign="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <ControlPointIcon
                            sx={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid xs={12} marginBottom={"12px"}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    Select age of your family members
                  </p>
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                  <EVDateInput
                    id="first_adult_dob"
                    className="inputField"
                    title={"1st Adult DOB"}
                    value={formData?.firstAdult_dob?.value}
                    attrName={"firstAdult_dob"}
                    value_update={updateMasterState}
                    warn_status={formData.firstAdult_dob.warning}
                    error_message={"Enter First Adult dob"}
                    min_date={15 * 12}
                    max_date={3}
                    date_validation_type="MONTHS"
                  />
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                  <NativeSelectDropdown
                    class_name="inputField"
                    title={"Gender"}
                    attrName={"gender"}
                    value={formData.gender.value}
                    value_update={updateMasterState}
                    data={gender_data}
                    warn_status={formData.gender.warning}
                    error_message={"Select gender"}
                  />
                </Grid>
                {memberStatus.twoAdults && (
                  <>
                    <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                      <EVDateInput
                        id="second_adult_dob"
                        className="inputField"
                        title={"2nd Adult DOB"}
                        value={formData?.secondAdult_dob?.value}
                        attrName={"secondAdult_dob"}
                        value_update={updateMasterState}
                        warn_status={formData.secondAdult_dob.warning}
                        error_message={"Enter Second Adult dob"}
                        min_date={15 * 12}
                        max_date={3}
                        date_validation_type="MONTHS"
                      />
                    </Grid>
                    <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                      <NativeSelectDropdown
                        class_name="inputField"
                        title={"Select Relationship"}
                        attrName={"relationship"}
                        value={formData.relationship.value}
                        value_update={updateMasterState}
                        data={relationship_data}
                        warn_status={formData.relationship.warning}
                        error_message={"Select relation"}
                      />
                    </Grid>
                  </>
                )}
                {memberStatus.son && (
                  <>
                    {[...Array(sonCount)].map((_, index) => (
                      <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                        <EVDateInput
                          id="son_dob"
                          className="inputField"
                          title={`Son ${index + 1} DOB`}
                          value={formData?.son_dob?.value}
                          attrName={"son_dob"}
                          value_update={updateMasterState}
                          warn_status={formData.son_dob.warning}
                          error_message={"Enter son DOB"}
                          min_date={15 * 12}
                          max_date={3}
                          date_validation_type="MONTHS"
                        />
                      </Grid>
                    ))}
                  </>
                )}
                {memberStatus.daughter && (
                  <>
                    {[...Array(daughterCount)].map((_, index) => (
                      <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                        <EVDateInput
                          id="daughter_dob"
                          className="inputField"
                          title={"Daughter DOB"}
                          value={formData?.daughter_dob?.value}
                          attrName={"daughter_dob"}
                          value_update={updateMasterState}
                          warn_status={formData.daughter_dob.warning}
                          error_message={"Enter Daughter DOB"}
                          min_date={15 * 12}
                          max_date={3}
                          date_validation_type="MONTHS"
                        />
                      </Grid>
                    ))}
                  </>
                )}

                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="Continue"
                    onClick={() => setHealthFormSteps("healthStep2")}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            ) : healthFormSteps === "healthStep2" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid xs={12} textAlign={"center"} marginBottom={"12px"}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    Enter Personal Details
                  </p>
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingRight={"6px"}>
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={"Enter Mobile"}
                  />
                </Grid>
                <Grid xs={6} marginBottom={"20px"} paddingLeft={"6px"}>
                  <RKTextField
                    class_name="inputField"
                    title={"Enter your pincode"}
                    value={formData.pincode.value}
                    attrName={"pincode"}
                    value_update={updateMasterState}
                    warn_status={formData.pincode.warning}
                    error_message={"Enter pincode"}
                  />
                </Grid>

                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="View Quotes"
                    onClick={() => {}}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid
                  xs={12}
                  textAlign={"center"}
                  marginBottom={"24px"}
                  className="popup-Checkbox"
                >
                  <CustomCheckbox
                    label={
                      <>
                        By clicking on view quotes you agree to all
                        <span> Terms & Conditions.</span>
                      </>
                    }
                    attrName={"termCondition"}
                    value={true}
                    // value_update={handleCheckbox}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MHealthForm;
